import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import * as styles from './Loading.module.scss';

export type LoadingProps = {
    label?: string;
};

function Loading({ label }: LoadingProps) {
    return (
        <div className={styles.root}>
            <CircularProgress aria-label={label ?? 'loading details'} />
        </div>
    );
}

export default Loading;
