import { IS_BROWSER } from 'core/constants';
import { isJSON } from './util';

const LOCALSTORAGE_ERROR_PREFIX = 'localStorage error';
const SESSIONSTORAGE_ERROR_PREFIX = 'sessionStorage error';

/**
 * Loads an object or string from localStorage for a given key
 * @param {string} key localStorage key
 * @returns {Record<string, unknown> | string | null} Data stored in localstorage for the given key
 */
export function loadStorage(
    key: string,
): Record<string, unknown> | string | null {
    if (!IS_BROWSER || typeof localStorage === 'undefined') {
        return null;
    }

    const serializedState = localStorage.getItem(key) ?? null;

    if (serializedState === null) {
        return null;
    }

    const parsedJSON = isJSON(serializedState);

    if (parsedJSON) {
        return parsedJSON;
    }
    return serializedState;
}

/**
 * Saves an object or string to localStorage for a given key
 * @param {string} key localStorage key
 * @param {Record<string, unknown> | string} state Data to store in localStorage for the given key
 */
export function saveStorage(
    key: string,
    state: Record<string, unknown> | string,
): void {
    if (!IS_BROWSER) return;

    try {
        const serializedState =
            typeof state === 'string' ? state : JSON.stringify(state);
        localStorage.setItem(key, serializedState);
    } catch (error) {
        console.error(`${LOCALSTORAGE_ERROR_PREFIX} ${error}`);
    }
}

/**
 * Removes the key and its associated data from localStorage
 * @param {string[]} keys List of keys to remove from localStorage
 */
export function removeStorage(keys: string[]): void {
    if (!IS_BROWSER) return;

    try {
        keys.forEach((key) => localStorage.removeItem(key));
    } catch (error) {
        console.error(`${LOCALSTORAGE_ERROR_PREFIX}: ${error}`);
    }
}

export function loadSessionStorage<Data = unknown>(key: string): Data | null {
    if (!IS_BROWSER || typeof sessionStorage === 'undefined') {
        return null;
    }

    const serializedState = sessionStorage.getItem(key) ?? null;

    if (serializedState === null) {
        return null;
    }

    const parsedJSON = isJSON(serializedState);

    if (parsedJSON) {
        return parsedJSON as Data;
    }

    return serializedState as Data;
}

export function saveSessionStorage<Data = unknown>(
    key: string,
    state: Data,
): void {
    if (!IS_BROWSER) return;

    try {
        const serializedState =
            typeof state === 'string' ? state : JSON.stringify(state);
        sessionStorage.setItem(key, serializedState);
    } catch (error) {
        console.error('%s %s', SESSIONSTORAGE_ERROR_PREFIX, error);
    }
}
