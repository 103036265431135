export const BREAKPOINT_MD_MIN = 768;
export const BREAKPOINT_LG_MIN = 900;
export const BREAKPOINT_XL_MIN = 1244;

export const PHONE_MASKING_SYMBOL = '(•••) ••• -';
export const CREDITCARD_MASKING_SYMBOL = '•••• •••• ••••';

export const BOOKING_URL = 'booking';

export const FALLBACK_TIMEZONE =
    process.env.GATSBY_DEFAULT_TIMEZONE ?? 'America/New_York';

export const CLIENT_APP_URL =
    process.env.GATSBY_CLIENT_APP_URL ?? 'https://app.bondvet.com';

export const IS_BROWSER = typeof window !== 'undefined';

export const MEDICAL_ROLES = [
    'Veterinarian',
    'Associate Medical Director',
    'Medical Director',
    'Associate Regional Medical Director',
    'Regional Medical Director',
    'Specialty Surgeon',
];

export const SHOW_OCULAR_SURGERY_PAGE =
    process.env.GATSBY_SHOW_OCULAR_SURGERY_PAGE === 'true';
