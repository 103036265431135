import { ScriptStrategy } from 'gatsby';
import { IS_BROWSER } from 'core/constants';

function injectScript(
    timeoutMs: number = 0,
    src?: string,
    content?: string,
    attributes?: Record<string, string>,
): () => void {
    let script: ReturnType<typeof document.createElement>;
    if (src || content) {
        setTimeout(() => {
            script = document.createElement('script');

            if (src) {
                script.setAttribute('src', src);
            } else if (content) {
                script.appendChild(document.createTextNode(content));
            }

            if (attributes) {
                for (const [key, value] of Object.entries(attributes)) {
                    script.setAttribute(key, value);
                }
            }

            document.body.appendChild(script);
        }, timeoutMs);
    }

    return () => {
        if (script) {
            script.parentElement?.removeChild(script);
        }
    };
}

export default function renderScript(
    strategy: Exclude<ScriptStrategy, ScriptStrategy.offMainThread>,
    timeoutMs: number = 0,
    src?: string,
    content?: string,
    attributes?: Record<string, string>,
): () => void {
    if (!IS_BROWSER || (!src && !content)) {
        return () => {};
    }

    const unregisterRef: { current?: () => void } = {};

    switch (strategy) {
        case ScriptStrategy.postHydrate:
            unregisterRef.current = injectScript(
                timeoutMs,
                src,
                content,
                attributes,
            );
            break;
        case ScriptStrategy.idle:
            if ('requestIdleCallback' in window) {
                requestIdleCallback(() => {
                    unregisterRef.current = injectScript(
                        timeoutMs,
                        src,
                        content,
                        attributes,
                    );
                });
            } else {
                setTimeout(() => {
                    unregisterRef.current = injectScript(
                        timeoutMs,
                        src,
                        content,
                        attributes,
                    );
                }, 500);
            }
            break;
        default:
            // noop
            break;
    }

    return () => {
        if (unregisterRef.current) {
            unregisterRef.current();
        }
    };
}
