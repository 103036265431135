import { IS_BROWSER } from 'core/constants';

export const HAS_GA = IS_BROWSER && typeof window.gtag === 'function';
export const HAS_GA4 = HAS_GA && !!process.env.GATSBY_GOOGLE_ANALYTICS_4_ID;

export const HAS_BING_TRACKING_PIXEL = !!process.env.GATSBY_BING_TRACKING_PIXEL;

export const PINTEREST_PIXEL_ID = process.env.GATSBY_PINTEREST_PIXEL_ID;

export const HAS_PINTEREST_TRACKING_PIXEL = !!PINTEREST_PIXEL_ID;

export const HAS_YELP_PIXEL_ID = !!process.env.GATSBY_ADROLL_PIXEL_ID;

export const HAS_CONVERT =
    !!process.env.GATSBY_CONVERT_ACCOUNT_NUMBER &&
    !!process.env.GATSBY_CONVERT_PROJECT_NUMBER;

export const MNTN_ADVERTISER_ID = process.env.GATSBY_MNTN_ADVERTISER_ID;

export const IHEART_RADIO_PIXEL_ID = process.env.GATSBY_IHEART_RADIO_PIXEL_ID;

export const NEXTDOOR_PIXEL_ID = process.env.GATSBY_NEXTDOOR_PIXEL_ID;

export const QUORA_PIXEL_ID = process.env.GATSBY_QUORA_PIXEL_ID;

export const REDDIT_PIXEL_ID = process.env.GATSBY_REDDIT_PIXEL_ID;
