import { loadStorage, removeStorage, saveStorage } from 'lib/storage';
import { IS_BROWSER } from 'core/constants';

export const CLIENT_INFO_STORAGE_PATH = 'clientInfo';

export type ClientInfo = {
    clientId: string;
    clientEmail: string;
};

type Listener = (newClientInfo: ClientInfo | null) => void;

const LISTENERS: Array<Listener> = [];

function notifyListeners(newClientInfo: ClientInfo | null) {
    if (LISTENERS.length > 0) {
        for (const listener of LISTENERS) {
            try {
                listener(newClientInfo);
            } catch (error) {
                console.warn(
                    'error notifying %s listener: %s',
                    CLIENT_INFO_STORAGE_PATH,
                    (error as Error).message,
                );
            }
        }
    }
}

export function getClientInfo(): ClientInfo | null {
    const clientInfo = loadStorage(CLIENT_INFO_STORAGE_PATH);

    if (!clientInfo || typeof clientInfo === 'string') {
        return null;
    }

    return clientInfo as ClientInfo;
}

export function saveClientInfo(clientInfo: ClientInfo): void {
    saveStorage(CLIENT_INFO_STORAGE_PATH, clientInfo);

    notifyListeners(clientInfo);
}

export function clearClientInfo(): void {
    removeStorage([CLIENT_INFO_STORAGE_PATH]);

    notifyListeners(null);
}

export function onChange(
    listener: Listener,
    getInitialValue = false,
): () => void {
    if (IS_BROWSER) {
        LISTENERS.push(listener);

        if (getInitialValue) {
            setTimeout(() => {
                listener(getClientInfo());
            }, 5000);
        }

        return () => {
            const idx = LISTENERS.indexOf(listener);

            if (idx !== -1) {
                LISTENERS.splice(idx, 1);
            }
        };
    }

    return () => {};
}
