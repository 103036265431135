import * as React from 'react';
import {
    ScriptStrategy,
    WrapPageElementNodeArgs,
    WrapRootElementNodeArgs,
} from 'gatsby';
import Script from 'analytics/components/Script';
import App from './gatsby/App';

export function wrapRootElement({ element }: WrapRootElementNodeArgs) {
    return <App>{element}</App>;
}

const myFontsScript = `const link = document.getElementById('my-fonts-count');
    if (link) {
        link.setAttribute('onload', 'this.rel="stylesheet";this.onload=null;');
    }`;

const BING_TRACKING_PIXEL = process.env.GATSBY_BING_TRACKING_PIXEL;
const ADROLL_ADV_ID = process.env.GATSBY_ADROLL_ADV_ID;
const ADROLL_PIXEL_ID = process.env.GATSBY_ADROLL_PIXEL_ID;

function accessibeSetup() {
    if (process.env.GATSBY_HAS_ACCESSIBE === 'true') {
        return (
            <Script
                strategy={ScriptStrategy.idle}
                content={`(function () {
    const s = document.createElement('script');
    const e = !document.body ? document.querySelector('head') : document.body;
    s.src = 'https://acsbapp.com/apps/app/assets/js/acsb.js';
    s.async = s.defer = true;
    s.onload = function () {
        acsbJS.init({
            statementLink: '',
            feedbackLink: '',
            footerHtml: '',
            hideMobile: false,
            hideTrigger: true,
            language: 'en',
            position: 'left',
            leadColor: '#11355a',
            triggerColor: '#11355a',
            triggerRadius: '50%',
            triggerPositionX: 'left',
            triggerPositionY: 'bottom',
            triggerIcon: 'default',
            triggerSize: 'medium',
            triggerOffsetX: 20,
            triggerOffsetY: 20,
            mobile: {
                triggerSize: 'medium',
                triggerPositionX: 'left',
                triggerPositionY: 'bottom',
                triggerOffsetX: 0,
                triggerOffsetY: 0,
                triggerRadius: '0',
            },
        });
    };
    e.appendChild(s);
})();
`}
            />
        );
    }

    return null;
}

function bingPixelSetup() {
    if (BING_TRACKING_PIXEL) {
        /* eslint-disable max-len */
        return (
            <Script
                strategy={ScriptStrategy.idle}
                timeoutMs={2000}
                content={`(function(w, d, t, r, u) {
    var f, n, i;
    w[u] = w[u] || [], f = function() {
        var o = { ti: '${BING_TRACKING_PIXEL}' };
        o.q = w[u], w[u] = new UET(o), w[u].push('pageLoad');
    }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function() {
        var s = this.readyState;
        s && s !== 'loaded' && s !== 'complete' || (f(), n.onload = n.onreadystatechange = null);
    }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i);
})(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');`}
            />
        );
    }

    return null;
}

function yelpPixelSetup() {
    if (ADROLL_PIXEL_ID) {
        return (
            <Script
                strategy={ScriptStrategy.idle}
                timeoutMs={2000}
                content={`
    adroll_pix_id = "${ADROLL_PIXEL_ID}"
    adroll_adv_id = "${ADROLL_ADV_ID}"
    adroll_version = "2.0";

    (function(w, d, e, o, a) {
        w.__adroll_loaded = true;
        w.adroll = w.adroll || [];
        w.adroll.f = [ 'setProperties', 'identify', 'track' ];
        var roundtripUrl = "https://s.adroll.com/j/${ADROLL_ADV_ID}/roundtrip.js";
        for (a = 0; a < w.adroll.f.length; a++) {
            w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
                return function() {
                    w.adroll.push([ n, arguments ])
                }
            })(w.adroll.f[a])
        }

        e = d.createElement('script');
        o = d.getElementsByTagName('script')[0];
        e.async = 1;
        e.src = roundtripUrl;
        o.parentNode.insertBefore(e, o);
    })(window, document);
    adroll.track("pageView");`}
            />
        );
    }
    return null;
}

function convertABScript() {
    const accountNumber = process.env.GATSBY_CONVERT_ACCOUNT_NUMBER;
    const projectNumber = process.env.GATSBY_CONVERT_PROJECT_NUMBER;

    if (!accountNumber || !projectNumber) {
        return null;
    }

    return (
        <Script
            strategy={ScriptStrategy.postHydrate}
            src={`https://cdn-4.convertexperiments.com/js/${accountNumber}-${projectNumber}.js`}
        />
    );
}

function adaChatBotSetup() {
    const showBot = process.env.GATSBY_ENABLE_ADA_CHAT_BOT === 'true';
    const handle = process.env.GATSBY_ADA_CHAT_BOT_HANDLE;
    const testMode = process.env.GATSBY_ADA_TEST_MODE === 'true';

    if (!showBot || !handle) {
        return null;
    }

    return (
        <>
            <Script
                strategy={ScriptStrategy.idle}
                timeoutMs={100}
                content={`window.adaSettings = {
    testMode: ${testMode},
};
`}
            />
            <Script
                strategy={ScriptStrategy.idle}
                timeoutMs={5000}
                attributes={{ id: '__ada', 'data-handle': handle }}
                src="https://static.ada.support/embed2.js"
            />
        </>
    );
}

export const wrapPageElement = ({
    element,
    props,
}: WrapPageElementNodeArgs) => {
    const isBookingPage = props.location.pathname.includes('/booking');
    return (
        <>
            {element}
            {accessibeSetup()}
            {bingPixelSetup()}
            <Script
                strategy={ScriptStrategy.idle}
                timeoutMs={5000}
                content={myFontsScript}
            />
            <script
                dangerouslySetInnerHTML={{
                    __html: `document.documentElement.classList.remove('no-js');
    // detect firefox
    if (navigator.userAgent.match(/firefox/i)) {
        document.documentElement.classList.add('ff');
    } else {
        document.documentElement.classList.add('no-ff');
    }`,
                }}
            />
            {/* needs to be in head though I think */ convertABScript()}
            {yelpPixelSetup()}
            {!isBookingPage && adaChatBotSetup()}
        </>
    );
};
