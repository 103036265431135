import {
    getImage,
    IGatsbyImageData,
    withArtDirection,
} from 'gatsby-plugin-image';
import * as React from 'react';
import { GoogleReviewsLocation } from 'locations/lib/types';
import { Base64 } from 'js-base64';
import { saveAs } from 'file-saver';
import parse, {
    DOMNode,
    Element,
    HTMLReactParserOptions,
} from 'html-react-parser';
import { Link } from 'gatsby';
import { GoogleReviews, ResponsiveImage, Location } from './types';

export function createId(): string {
    return Math.random().toString(36).substr(2, 9);
}

export function parseString(value?: string): string {
    return value || '';
}

export function getImages(
    image?: ResponsiveImage | null,
): IGatsbyImageData | null {
    if (image) {
        const { mobile, desktop } = image;

        if (!mobile && !desktop) {
            return null;
        }

        if (mobile && !desktop) {
            return getImage(mobile) as IGatsbyImageData;
        }

        if (desktop && !mobile) {
            return getImage(desktop) as IGatsbyImageData;
        }

        const mobileImage = getImage(mobile);
        const desktopImage = getImage(desktop);

        return withArtDirection(desktopImage as IGatsbyImageData, [
            {
                media: '(max-width: 767.9999px)',
                image: mobileImage as IGatsbyImageData,
            },
        ]);
    }

    return null;
}

export async function sha256(email: string) {
    const msgBuffer = new TextEncoder().encode(email.trim().toLowerCase());
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
}

export function aggregateGoogleReviews(
    locations: ReadonlyArray<Location> | ReadonlyArray<GoogleReviewsLocation>,
): GoogleReviews {
    const compoundReviews: GoogleReviews = {
        averageRating: 0,
        bestRating: 0,
        reviews: [],
        totalReviewCount: 0,
        worstRating: 5,
    };

    let ratingSum = 0;
    let count = 0;

    if (locations) {
        locations.forEach((loc: Location | GoogleReviewsLocation) => {
            if (loc?.googleReviews) {
                ratingSum += loc.googleReviews.averageRating;
                count += 1;
                compoundReviews.totalReviewCount +=
                    loc.googleReviews.totalReviewCount;
                compoundReviews.bestRating = Math.max(
                    compoundReviews.bestRating,
                    loc.googleReviews.bestRating,
                );
                compoundReviews.worstRating = Math.min(
                    compoundReviews.worstRating,
                    loc.googleReviews.worstRating,
                );
                compoundReviews.reviews.push(...loc.googleReviews.reviews);
            }
        });
    }

    if (count > 0) {
        compoundReviews.averageRating = ratingSum / count;
    } else {
        compoundReviews.worstRating = 0;
    }

    return compoundReviews;
}

// returns white or black color depending on input color so that text
// is hopefully always readable on background. Using the following algorithm:
// eslint-disable-next-line max-len
// https://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
export function getComplementaryTextColor(hexColor: string): string | null {
    const h =
        /^#?(([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})|([a-f\d])([a-f\d])([a-f\d]))$/i.exec(
            hexColor,
        );
    if (h && h.length > 6) {
        let color;
        if (h[2] && h[3] && h[4]) {
            // color code is 6 characters long
            color = {
                r: parseInt(h[2], 16),
                g: parseInt(h[3], 16),
                b: parseInt(h[4], 16),
            };
        } else if (h[5] && h[6] && h[7]) {
            // color code is 3 characters long
            color = {
                r: parseInt(h[5] + h[5], 16),
                g: parseInt(h[6] + h[6], 16),
                b: parseInt(h[7] + h[7], 16),
            };
        }
        if (!color) {
            return null;
        }
        const brightness = Math.sqrt(
            color.r * color.r * 0.241 +
                color.g * color.g * 0.691 +
                color.b * color.b * 0.068,
        );
        return brightness < 130 ? '#FFF' : '#000';
    }
    return null;
}

/**
 * Tests a string for parseable JSON
 * @param testString String to test
 * @returns a parsed JSON object if the string can be parsed, otherwise returns null
 */
export function isJSON(testString: string): Record<string, unknown> | null {
    try {
        return JSON.parse(testString);
    } catch {
        return null;
    }
}

export function savePdf(base64Data: string, fileName: string): void {
    const binaryContent = Base64.toUint8Array(base64Data);
    const blob = new Blob([binaryContent], {
        type: 'application/octet-stream',
    });

    saveAs(blob, fileName);
}

/**
 * format a hh:mm string to 12-hour format
 * @param time hh:mm string
 * @returns the time in 12-hour format
 */
export const formatTime = (time: string) => {
    const [hour, minutes] = time.split(':').map((t) => parseInt(t, 10));
    return [
        hour === 0 ? '12' : hour > 12 ? hour - 12 : hour,
        minutes !== 0 ? `:${minutes}` : '',
        hour > 12 ? 'PM' : 'AM',
    ].join('');
};

/**
 * parse a CMS-text that can be edited in <TinaTextEditor /> to HTML
 * @param text is a string value that can be parsed into html
 * @param defaultText default string value if the text is empty, must also be parseable into html
 * @returns parsed html or null if the text was empty
 */
export function parseCMSText(
    text?: string | null,
    defaultText?: string,
): string | JSX.Element | JSX.Element[] | null {
    const parseOptions: HTMLReactParserOptions = {
        replace: (node: DOMNode) => {
            if (node instanceof Element) {
                // replace internal links with gatsby's
                // <Link /> component
                if (node.name === 'a' && node.attribs.href.startsWith('/')) {
                    // Replace the <a> element with a gatsby <Link> element for internal Links
                    const newProps = {
                        ...node.attribs,
                        href: undefined,
                        to: node.attribs.href,
                    };
                    return (
                        <Link {...newProps}>{node.children[0].data}</Link>
                    ) as JSX.Element;
                }
            }

            return null;
        },
    };
    if (text) {
        const parsedText = parse(text, parseOptions);

        // check for empty <p /> elements
        if (
            Array.isArray(parsedText) ||
            typeof parsedText === 'string' ||
            !(parsedText.type === 'p' && parsedText.props.children === null)
        ) {
            return parsedText;
        }
    }

    if (defaultText) {
        return parse(defaultText, parseOptions);
    }

    return null;
}

export function getValidToken() {
    if (typeof window !== 'undefined') {
        const authToken = window.sessionStorage.getItem('authToken');
        const authEmail = window.sessionStorage.getItem('authEmail');
        const authTokenExpiration = window.sessionStorage.getItem(
            'authTokenExpiration',
        );

        if (authToken && authEmail && authTokenExpiration) {
            const expirationDate = new Date(parseInt(authTokenExpiration, 10));
            const currentDate = new Date();

            if (currentDate < expirationDate) {
                return authToken;
            }
            // Remove expired token and email
            window.sessionStorage.removeItem('authToken');
            window.sessionStorage.removeItem('authEmail');
            window.sessionStorage.removeItem('authTokenExpiration');
        }
    }
    return null;
}
