import { isAutomatedTest } from 'core/lib/isAutomatedTest';
import { GoogleAnalyticsTrackingStatus } from '@bondvet/types/booking';
import { getClientInfo } from './lib/clientInfo';

let initializedGoogleAnalytics4Id: string | null = null;
let initializedGoogleAnalytics4UserId: string | null = null;

const GOOGLE_ANALYTICS_EVENT_TIMEOUT_MS = 2000;

function shouldTrack(trackInAutomatedTest: boolean): boolean {
    if (trackInAutomatedTest) {
        return true;
    }

    if (isAutomatedTest()) {
        // eslint-disable-next-line no-console
        console.log(
            'automated test detected - skipping Google Analytics tracking',
        );
        return false;
    }

    return true;
}

export async function getGoogleAnalyticsTrackingStatus(): Promise<GoogleAnalyticsTrackingStatus> {
    return new Promise((resolve) => {
        import('./constants').then(({ HAS_GA }) => {
            if (!HAS_GA) {
                resolve({
                    enabled: false,
                    blocked: false,
                });
            } else {
                let resolved = false;
                const startTime = new Date().getTime();
                window.gtag?.('event', 'test_blocked_tracking', {
                    event_callback: () => {
                        const durationMs = new Date().getTime() - startTime;
                        // eslint-disable-next-line no-console
                        console.info(
                            `Google Analytics responded within ${durationMs}ms.`,
                        );

                        resolved = true;
                        resolve({
                            enabled: true,
                            blocked: false,
                        });
                    },
                });
                setTimeout(() => {
                    if (!resolved) {
                        // eslint-disable-next-line no-console
                        console.info(
                            'Google Analytics did not respond within ' +
                                `${GOOGLE_ANALYTICS_EVENT_TIMEOUT_MS}ms, assuming it's blocked.`,
                        );

                        resolve({
                            enabled: true,
                            blocked: true,
                        });
                    }
                    // add a grace period to make sure the event callback is called
                }, GOOGLE_ANALYTICS_EVENT_TIMEOUT_MS);
            }
        });
    });
}

export function trackWithAnyGoogleAnalytics(
    trackInAutomatedTest: boolean,
    cmd: string,
    ...args: unknown[]
): void {
    if (shouldTrack(trackInAutomatedTest)) {
        import('./constants').then(({ HAS_GA }) => {
            if (HAS_GA) {
                // _we_ know that `window.gtag` is a function, due
                // to `HAS_GA` being true, but typescript doesn't
                // know that, so we keep the optional chaining
                try {
                    window.gtag?.(cmd, ...args);
                } catch (error) {
                    console.warn(
                        'error submitting GA command',
                        (error as Error).message,
                    );
                }
            }
        });
    }
}

export function trackWithGoogleAnalytics4(
    trackInAutomatedTest: boolean,
    cmd: string,
    ...args: unknown[]
): void {
    if (shouldTrack(trackInAutomatedTest)) {
        import('./constants').then(({ HAS_GA4 }) => {
            if (HAS_GA4) {
                // `HAS_GA4` being `true` means that `HAS_GA` is
                // `true` as well. but again, TypeScript doesn't
                // know that, hence the optional chaining
                try {
                    window.gtag?.(cmd, ...args);
                } catch (error) {
                    console.warn(
                        'error submitting GA4 command',
                        (error as Error).message,
                    );
                }
            }
        });
    }
}

export function trackGA4OnPageLoad(): void {
    import('./constants').then(({ HAS_GA4 }) => {
        if (HAS_GA4) {
            if (
                initializedGoogleAnalytics4Id !==
                process.env.GATSBY_GOOGLE_ANALYTICS_4_ID
            ) {
                trackWithGoogleAnalytics4(
                    true,
                    'config',
                    process.env.GATSBY_GOOGLE_ANALYTICS_4_ID,
                );

                trackWithGoogleAnalytics4(
                    true,
                    'config',
                    process.env.GATSBY_GOOGLE_ADWORDS_ID,
                );

                initializedGoogleAnalytics4Id =
                    process.env.GATSBY_GOOGLE_ANALYTICS_4_ID || null;
            }

            const clientInfo = getClientInfo();

            if (initializedGoogleAnalytics4UserId !== clientInfo?.clientId) {
                trackWithGoogleAnalytics4(true, 'set', {
                    user_id: clientInfo?.clientId,
                });

                initializedGoogleAnalytics4UserId =
                    clientInfo?.clientId || null;
            }
        }
    });
}
