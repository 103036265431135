import 'normalize.css';
import 'styles/scss/global.scss';
import { VOGLIO_FIELDS } from '@bondvet/types/voglio';
import { HAS_CONVERT } from 'analytics/constants';
import { trackGA4OnPageLoad } from 'analytics/ga';

export { wrapPageElement, wrapRootElement } from './gatsby-shared';

export const onRouteUpdate = ({
    location,
}: {
    location: Record<string, string>;
}) => {
    const params = new URLSearchParams(location.search);

    // save voglio params to local storage as individual keys
    VOGLIO_FIELDS.forEach((key) => {
        const value = params.get(key);
        if (value) {
            // if we already have something in localStorage, don't overwrite it
            // we want to keep the original value in localStorage
            if (!localStorage.getItem(key)) {
                localStorage.setItem(key, value);
            }
            sessionStorage.setItem(key, value);
        }
    });

    // track page view with Convert, if configured
    if (HAS_CONVERT) {
        window._conv_q = window._conv_q || [];
        window._conv_q.push(['run', 'true']);
    }

    if (window) {
        window.zESettings = {
            webWidget: {
                color: {
                    launcher: '#103559',
                    header: '#10365A',
                    resultLists: '#295889',
                    button: '#103559',
                    articleLinks: '#FF4D4D',
                },
            },
        };
    }

    if (location.pathname.includes('/booking')) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.zE && window.zE('webWidget', 'hide');
    } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.zE && window.zE('webWidget', 'show');
    }

    if (location.pathname === '/booking/finished') {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'conversion', {
                send_to: process.env.GATSBY_GOOGLE_CONVERSION_EVENT,
            });
        }
    }

    trackGA4OnPageLoad();

    return null;
};

export function onClientEntry() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('modernizr').then();
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!('IntersectionObserver' in window)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        import('intersection-observer').then();
    }
}

// automatically reload when the service worker has been updated
export const onServiceWorkerUpdateReady = () => {
    window.location.reload();
};
